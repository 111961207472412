/*eslint no-unused-vars: "off"*/

/**
 * 
 * @see: https://hackernoon.com/formik-handling-files-and-recaptcha-209cbeae10bc
 * @see: https://codesandbox.io/s/w7m717779w
 * @see: https://codesandbox.io/s/p5x3m40w77?from-embed
 * @see: https://www.drupal.org/project/webform_rest/issues/2899902 // HOW TO SEND A FILE VIA REST!!!
 * @see: https://www.drupal.org/project/webform/issues/2960359
 * 
 * @see: https://codesandbox.io/s/pjqp3xxq7q?from-embed // chekbox validation
 * @see: https://material-ui.com/components/checkboxes/
 * 
 */
import React from 'react';
import { withStyles, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input, Checkbox, FormControlLabel, CircularProgress, Select } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
import { DisplayFormikState } from "./../formikHelper"

import injectData from "./../../../utils/ReactComponent/injectData"
import executeQuery from "./../../s4n/Harmonogram/query"
import CollectionDataUtils from "./../../../utils/Collection/data"
import Harmonogram from './../../../model/Harmonogram'

import axios from 'axios';
// import Dropzone from "react-dropzone";
import Recaptcha from "react-recaptcha";
// import Thumb from "./Thumb";



// const oFormHelper = new FormHelper();

const styles = {

};

class ObjectStatefull extends React.Component {
  constructor(props) {
    super(props)

    // this.onSubmit = this.onSubmit.bind(this) // or use arrow function

    const { intl, harmonogramNode } = this.props;
    const t = intl.formatMessage;
    // this.t = this.t.bind(this);

    this.state = {
      loading: false,
      error: false,
      formSchema: null,

      harmonogramNode, // === harmonogramNode: harmonogramNode,
      agreement: false,
      formStatus: {
        success: null,
        class: null,
        message: null,
      },
    }

    this.uploadedFileNames = [];

    this.formSchema = {
      sections: [
        {
          title: t({ id: "soft4net_webform_harmonogram_form_section_orderer" }),
          elements: [
            'company_person', 'company_address_address', 'nip', 'krs',
          ]
        },
        {
          title: t({ id: "soft4net_webform_harmonogram_form_section_participant" }),
          elements: [
            'first_name_last_name', 'email', 'phone', 'place_of_birth',
          ]
        },
        {
          title: t({ id: "soft4net_webform_harmonogram_form_section_attachments" }),
          elements: [
            'zyciorys_zawodowy', 'zestawienie_referencji', 'oswiadczenie_wyksztalcenia'
          ]
        }
      ]
    }

    this.validationSchema = Yup.object().shape({
      harmonogramNodeId: Yup.string().required(),

      company_person: Yup.string().required(),
      company_address_address: Yup.string().required(),
      nip: Yup.string(),
      krs: Yup.string(),
      first_name_last_name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.string(),
      place_of_birth: Yup.string(),
      
      zyciorys_zawodowy: Yup.mixed().required(), // value casted to object === {}
      zestawienie_referencji: Yup.mixed().required(), // value casted to object === {}
      oswiadczenie_wyksztalcenia: Yup.mixed().required(), // value casted to object === {}

      agreement: Yup.boolean().oneOf([true], t({ id: "soft4net_form_validation_message_boolean_oneOf" })),
      // agreement: Yup.boolean()
      //   .test(
      //     'agreement',
      //     'You have to agree with our Terms and Conditions!',
      //     value => value === true
      //   )
      //   .required(
      //     'You have to agree with our Terms and Conditions!'
      //   ),

      // recaptcha: Yup.string().required(),
    })

    this.initialValues = {
      harmonogramNodeId: props.harmonogramNode ? this.state.harmonogramNode.drupal_internal__nid : '',
      company_person: '',
      company_address_address: '',
      nip: '',
      krs: '',
      first_name_last_name: '',
      email: '',
      phone: '',
      place_of_birth: '',
      zyciorys_zawodowy: null,
      zestawienie_referencji: null,
      oswiadczenie_wyksztalcenia: null,
      agreement: false,
      // recaptcha: '',
      // attachments: [],
    }
  
    // this.initialValues = {
    //   company_person: 'company_person',
    //   company_address_address: 'company_address_address',
    //   nip: 'nip',
    //   krs: 'krs',
    //   first_name_last_name: 'first_name_last_name',
    //   email: 'email@email.com',
    //   phone: '12312423525',
    //   place_of_birth: 'place_of_birth',
    //   zyciorys_zawodowy: null,
    //   zestawienie_referencji: null,
    //   oswiadczenie_wyksztalcenia: null,
    //   agreement: false,
    //   recaptcha: '',
    //   // attachments: [],
    // }

    this.webform_id = 'heuresis_harmonogram_zgloszenie';
  }

  async componentDidMount() {
    
    if (typeof document !== `undefined`) {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
        
    // if (typeof document === `undefined`) {
    //   return;
    // }
    
    // const { intl } = this.props;
    // const t = intl.formatMessage;



    // // Set data for FormHelper START
    // // const oFormHelper = new FormHelper(intl);
    // oFormHelper.setIntl(intl);

    // oFormHelper.t = t;
    // // oFormHelper.setFormStatus = setFormStatus;
    // oFormHelper.setFormStatus = this.state.formStatus;
    // // Set data for FormHelper STOP
    
    // // oFormHelper.t = t;
    // // oFormHelper.setFormStatus = this.state.formStatus;
    // oFormHelper.state = this.state;
    // // oFormHelper.setState = this.setState;
    // oFormHelper.setState = null;
    // // Set data for FormHelper STOP

    // // Form schema does not contain markup elements!
    // const formSchema = await oFormHelper.getFormSchema(this.webform_id);

  }

  // t = (toTranslate) => {
  //   const { intl } = this.props;
  //   const t = intl.formatMessage;
  //   return t({ id: toTranslate })
  // }

  // @todo: REFACTOR FILE UPLOADS TO UTILS CLASS
  uploadFile = () => {
  }

  onSubmit = async (values, actions) => {
    const { intl } = this.props;
    const t = intl.formatMessage;

    //setSubmitting(true);
    actions.setStatus(undefined);

    let formData = new FormData();
    formData.append("recaptcha", values.recaptcha);
    // Dropzone
    // for (let i = 0; i <= values.attachments.length; i++) {
    //   formData.append(`attachments[${i}]`, values.attachments[i]);
    // }

    values.webform_id = this.webform_id;
    values['harmonogram_nid_'] = values.harmonogramNodeId; // translate to match drupal webform field machinename!

    axios.defaults.baseURL = process.env.SOFT4NET_SOURCE_BASE_URL;

    // try {
    // } catch (error) {
    // }

    const tokenResponce = await axios.get('/rest/session/token');
    const csrfToken = tokenResponce.data;



    const attachFiles = true;
    if (attachFiles) {
      let fileElements = this.formSchema.sections[this.formSchema.sections.length - 1].elements;
      // fileElements = []
  
      // console.log(fileElements)
  
      // fileElements.map(field_name => {
      //   formData.append(field_name, values[field_name]);
      // })
  
      // @see: https://futurestud.io/tutorials/node-js-how-to-run-an-asynchronous-function-in-array-map
      const promises = fileElements.map(async field_name => {
        formData.append(field_name, values[field_name]);
  
        const fileResponse = await axios.post(
          `/webform_rest/${this.webform_id}/upload/${field_name}?_format=json`, 
          formData.get(field_name),
          {
            headers: {
              'Content-Type': 'application/octet-stream', // 'Content-Type': 'application/json; charset=utf-8',
              'Content-Disposition': `file; filename="${formData.get(field_name).name}"`,
              'X-CSRF-Token': csrfToken,
              // 'Access-Control-Allow-Origin': '*',
            }
            // ,
            // auth: {
            //   username: process.env.BASIC_AUTH_USERNAME,
            //   password: process.env.BASIC_AUTH_PASSWORD,
            // }
        })
  
        return fileResponse.data.fid[0].value
      })
  
      // wait until all promises resolve
      const fileResponses = await Promise.all(promises)
  
      // console.log(fileResponses)
  
      fileResponses.map((fileResponseFidValue, index) => {
        values[fileElements[index]] = fileResponseFidValue;
      })
    }



    // console.log(values)

    let response = await axios.post('/webform_rest/submit',
      values,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
        auth: {
          username: process.env.BASIC_AUTH_USERNAME,
          password: process.env.BASIC_AUTH_PASSWORD,
        }
      });

    // console.log(response)

    if (response && response.status === 200 && response.data.sid) {
      this.setState({
        formStatus: {
          success: true,
          class: 'success',
          message: t({ id: "soft4net_form_status_success" })
        }
      });

      // @todo: this is not working check why!?
      actions.setStatus({
        success: true
      });
      actions.resetForm();
    } else {
      this.setState({
        formStatus: {
          success: true,
          class: 'danger',
          message: t({ id: "soft4net_form_status_danger" })
        }
      });
    }

    actions.setSubmitting(false);
  }

  handleChangeCheckbox = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.checked
    });

    // console.log(`name: `, name)
    // console.log(`event: `, event)

    // this.setState({
    //   // ...this.state,
    //   [name]: event.target.checked
    // });

    // this.setState(prevState => {
    //   return{
    //     ...prevState,
    //     [name]: event.target.checked
    //   }
    // })
  };

  renameFile = (originalFile, newName) => {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  render() {
    const { classes, intl, harmonogramNode } = this.props;
    const t = intl.formatMessage;

    // we cannot execute hook here therefore we inject data!!!
    // const data = executeQuery()
    const data = this.props.dataCustom;
    // prepare data collection, without duplications in one language because of Drupal translation fallback strategy if translation does not exists
    const aDataCollectionTranslatedHarmonograms = CollectionDataUtils.filterData(data.index.edges, intl.locale);
// console.log(`aDataCollectionTranslatedHarmonograms: `, aDataCollectionTranslatedHarmonograms);

// console.log(`this.formSchema: `, this.formSchema);


    const translation = {
      attach_required_files: {
          // pl: `Załącz wymagane pliki`,
          // en: `Attach required files`,
          pl: `Załącz wymagane pliki, jeżeli chcesz uzyskać certyfikat`,
          en: `Attach the required files if you want to obtain a certificate`,
          de: `Hängen Sie die erforderlichen Dateien an, wenn Sie ein Zertifikat erhalten möchten`,
          es: `Adjunte los archivos requeridos si desea obtener un certificado`,
      }
    }

    // const ref = React.useRef();

// console.log(`this.props.harmonogramNode: `, this.props.harmonogramNode);
    const field_page_category = this.props.harmonogramNode?.field_page_category || [];
// console.log(`field_page_category: `, field_page_category);

    return (
        <Formik
          id={Math.random().toString(36).substr(2)}
          key={Math.random().toString(36).substr(2)}

          // initialValues={{
          //   harmonogramNodeId: this.props.harmonogramNode ? this.state.harmonogramNode.drupal_internal__nid : '',
          //   company_person: '',
          //   company_address_address: '',
          //   nip: '',
          //   krs: '',
          //   first_name_last_name: '',
          //   email: '',
          //   phone: '',
          //   place_of_birth: '',
          //   zyciorys_zawodowy: null,
          //   zestawienie_referencji: null,
          //   oswiadczenie_wyksztalcenia: null,
          //   agreement: false,
          //   // recaptcha: '',
          //   // attachments: [],
          // }}
          initialValues={this.initialValues}
          onSubmit={this.onSubmit}
          validationSchema={this.validationSchema}
        >

          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,

              setFieldValue,

            } = props;

            // console.log(`values: `, values);
            // console.log(`errors: `, errors);

            // Set form status messages
            const formStatus = this.state.formStatus;

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            const selectHarmonogramClass = classNames({
              // "d-none": harmonogramNode,
            });

            const fileElements = this.formSchema.sections[this.formSchema.sections.length - 1].elements;

            return (
              <Form
                // id={Math.random().toString(36).substr(2)}
                // key={Math.random().toString(36).substr(2)}
                // onSubmit={handleSubmit} 
                className="harmonogram"
              >

                  {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}

                  <div className={selectHarmonogramClass}>
                    <h3>Harmonogram</h3>
                    <div className="row">
                      <div className="col">

                        <Select
                          id={`${values.webform_id}_harmonogramNodeId`}
                          name="harmonogramNodeId"
                          error={errors.harmonogramNodeId && touched.harmonogramNodeId}
                          helperText={(errors.harmonogramNodeId && touched.harmonogramNodeId) && errors.harmonogramNodeId}
                          value={values.harmonogramNodeId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{ display: 'block' }}
                        >
                          <option value=''>{ t({ id: "soft4net_node_harmonogram_place_all" }) }</option>
                          {aDataCollectionTranslatedHarmonograms
                            .filter(({ node: nodeHarmonogram }) => {
// console.log(`nodeHarmonogram: `, nodeHarmonogram);

                              if (!field_page_category?.length) {
                                return true;
                              }

                              const field_harmonogram_category = nodeHarmonogram?.field_harmonogram_category || [];
// console.log(`field_harmonogram_category: `, field_harmonogram_category);
                              const intersection = field_page_category.filter(element => field_harmonogram_category.includes(element));
                              return intersection.length;
                            })
                            .map(({ node }) => {
                                const oHarmonogramModel = new Harmonogram(node, intl);

                                return (
                                  <option value={node.drupal_internal__nid}>{node.title} ({oHarmonogramModel.getDate()})</option>
                                )
                            })
                          }
                        </Select>

                      </div>
                    </div>
                  </div>

                  {this.formSchema.sections.map((section, index) => {



                    // @todo: REFACTOR!!! - DO NOT ALLOW SECTION FILES
                    if (2 === index) {
                      return null;
                    }



                    return (
                      <React.Fragment>
                        <h3>{section.title}</h3>
                        <div className="row">
                          <div className="col">
                            {section.elements.map((element) => {
                              return (
                                <React.Fragment>
                                  {fileElements.includes(element) ? 
                                      // This is file input
                                      <React.Fragment>
                                        {/* <Input ___accept="image/*" id={element} name={element} type="file" className="d-none"
                                          onChange={(event) => {
                                            setFieldValue(element, event.currentTarget.files[0]);
                                          }} />
                                        <label htmlFor={element}>
                                          <Button variant="contained" color="primary" component="span" className={'orange'}>Upload</Button>
                                        </label>  */}
                                      </React.Fragment> :
                                      <TextField 
                                        id={`${values.webform_id}_${element}`}
                                        label={ t({ id: `soft4net_webform_harmonogram_form_section_element_${element}` }) }
                                        name={element}
                                        error={errors[element] && touched[element]}
                                        helperText={(errors[element] && touched[element]) && errors[element]}
                                        className={classes.textField}
                                        value={values[element]}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        margin="normal"
                                      />
                                  }
                                </React.Fragment>
                              )
                            })}                    
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}

                  <h3>{translation.attach_required_files[intl.locale]}</h3>
                  {/* <h3>{oFormHelper.getFieldTitle(`zalacz_wymagane_pliki`) || translation.attach_required_files[intl.locale]}</h3> */}
                  <div className="wp-block-soft4net-container-block bg-grey my-4 py-0">
                      <div className="wp-block-soft4net-container-block container py-3">
                          <div className="row">
                              <div className="col">

                                <div className="table-responsive">
                                <table className="table">
                                    <tr>
                                        <th>{ t({ id: "soft4net_webform_harmonogram_form_section_attachments_table_row_1_col_1" }) }</th>
                                        <th>{ t({ id: "soft4net_webform_harmonogram_form_section_attachments_table_row_1_col_2" }) }</th>
                                        <th>{ t({ id: "soft4net_webform_harmonogram_form_section_attachments_table_row_1_col_3" }) }</th>
                                        <th>{ t({ id: "soft4net_webform_harmonogram_form_section_attachments_table_row_1_col_4" }) }</th>
                                    </tr>

                                    {fileElements.map((fileElementName, index) => {
                                      return (
                                        <tr>
                                          <td>{ t({ id: `soft4net_webform_harmonogram_form_section_attachments_table_row_${index + 2}_col_1` }) }</td>
                                          <td>{ t({ id: `soft4net_webform_harmonogram_form_section_attachments_table_row_${index + 2}_col_2` }) }</td>
                                          <td>{ t({ id: `soft4net_webform_harmonogram_form_section_attachments_table_row_${index + 2}_col_3` }) }</td>
                                          <td>

                                              {/* <pre>{JSON.stringify(fileElementName, null, 4)}</pre> */}

                                              {/* @see: https://github.com/mui-org/material-ui/issues/9716 */}
                                              <Input ___accept="image/*" 
                                                // ref={ref}
                                                id={fileElementName}
                                                // id={`${values.webform_id}_${fileElementName}`}
                                                
                                                name={fileElementName} type="file" className="d-none form-control"
                                                onChange={(event) => {

                                                  let file = event.currentTarget.files[0];
                                                  
/*
                                                  Uncaught (in promise) TypeError: Failed to execute 'setRequestHeader' on 'XMLHttpRequest': String contains non ISO-8859-1 code point.
                                                      at setRequestHeader (xhr.js:126)
                                                      at Object.forEach (utils.js:238)
                                                      at dispatchXhrRequest (xhr.js:120)
                                                      at new Promise (<anonymous>)
                                                      at xhrAdapter (xhr.js:12)
                                                      at dispatchRequest (dispatchRequest.js:52)
*/
                                                  // @see: https://newbedev.com/how-do-i-convert-special-utf-8-chars-to-their-iso-8859-1-equivalent-using-javascript
                                                  let updatedFileName = unescape(encodeURIComponent(file.name));
// console.log(`updatedFileName: `, updatedFileName)
                                                  if (this.uploadedFileNames.includes(updatedFileName)) {
                                                    updatedFileName = `${this.uploadedFileNames.length + 1}-${updatedFileName}`;
                                                  }

                                                  this.uploadedFileNames.push(updatedFileName);

                                                  // @see: https://stackoverflow.com/questions/21720390/how-to-change-name-of-file-in-javascript-from-input-file
                                                  const renamedFile = this.renameFile(file, updatedFileName);
                                                  setFieldValue(fileElementName, renamedFile);

                                                  // Object.defineProperty(file, 'name', {
                                                  //   writable: true,
                                                  //   value: updatedFileName
                                                  // });
                                                  // setFieldValue(fileElementName, file);

                                                }} />
                                              <label htmlFor={fileElementName}>
                                                <Button variant="contained" color="primary" component="span" className={['orange', 'choose-file']}>
                                                  {values[fileElementName] ? decodeURIComponent(escape(values[fileElementName].name)) : t({ id: "soft4net_form_action_choose_file" }) }
                                                </Button>
                                              </label>

                                              {errors[fileElementName] && 
                                              // touched[fileElementName] &&
                                                <p className="MuiFormHelperText-root Mui-error">{errors[fileElementName]}</p>
                                              }

                                          </td>
                                        </tr>
                                      )
                                    })}

                                </table>
                                </div>

                              </div>
                          </div>
                      </div>
                  </div>



                  {/* Form status messages */}
                  <div className="row">
                    <div className="col">
                      <div className="alert alert-warning" role="alert">
                        { t({ id: "soft4net_webform_harmonogram_form_clause" }) }
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">

                      {/* <Field
                        component={Checkbox}
                        name="agreement"
                        id="agreement"
                        label={ t({ id: "soft4net_webform_harmonogram_form_label_agreement" }) }
                      /> */}

                      {/* <label for="agreement" className="form-field" htmlFor="agreement">
                        <input id="agreement" name="agreement" type="checkbox" onChange={handleChange} />
                        <span>{ t({ id: "soft4net_webform_harmonogram_form_label_agreement" }) }</span>
                      </label>
                      <div className="form-field-error">{errors.consent}</div> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="agreement"
                            onChange={handleChange}
                            checked={values.agreement}
                          />
                        }
                        label={ t({ id: "soft4net_webform_harmonogram_form_label_agreement" }) }
                      />
                      {errors.agreement && 
                      // touched.agreement &&
                        <p className="MuiFormHelperText-root Mui-error">{errors.agreement}</p>
                      }

                    </div>
                    <div className="col">

                      <div className="d-flex flex-column align-items-end ">
                          {/* <label>Recaptcha Validation</label> */}
                          {/* <Recaptcha
                            sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                            render="explicit"
                            theme="light"
                            verifyCallback={(response) => { setFieldValue(`recaptcha`, response); }}
                            onloadCallback={() => { 
                              // console.log("done loading!"); 
                            }}
                          /> */}
                          {errors.recaptcha && touched.recaptcha && (
                            <p className="MuiFormHelperText-root Mui-error mt-2">{errors.recaptcha}</p>
                          )}
                      </div>

                      </div>
                  </div>



                  {/* Form status messages */}
                  <div className="row form-status">
                    <div className="col">
                      <div className={formStatusClass} role="alert">
                        {formStatus.message}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <DialogActions>
                        <Button
                          type="button"
                          color="secondary"
                          variant="contained"
                          className="outline"
                          onClick={handleReset}
                          disabled={!dirty || isSubmitting}
                        >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                        {isSubmitting ? 
                          <CircularProgress /> : 
                          <Button 
                            type="submit" 
                            variant="contained" 
                            color="primary"
                            // className={formStatus && formStatus.success ? 'green' : null}
                            disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                        }

                        {/* <DisplayFormikState {...props} /> */}
                      </DialogActions>

                    </div>
                  </div>

                  {/* <div className="form-group">
                    <label>Multiple files</label>
                    <Dropzone style={dropzoneStyle} accept="image/*" onDrop={(acceptedFiles) => {
                      // do nothing if no files
                      if (acceptedFiles.length === 0) { return; }

                      // on drop we add to the existing files
                      setFieldValue("attachments", values.attachments.concat(acceptedFiles));
                    }}>
                      {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragActive) {
                          return "This file is authorized";
                        }

                        if (isDragReject) {
                          return "This file is not authorized";
                        }

                        if (values.attachments.length === 0) {
                          return <p>Try dragging a file here!</p>
                        }

                        return values.attachments.map((file, i) => (
                            <Thumb key={i} file={file} />
                        ));
                      }}
                    </Dropzone>
                  </div> */}

              </Form>
            );
          }}

        </Formik>
    );
  }
};

export default withStyles(styles)(
  injectIntl(
    injectData(ObjectStatefull, 'dataCustom', executeQuery)
  )
)